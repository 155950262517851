import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const keyboardLayout = {
  default: [
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "{bksp} Z X C V B N M {enter}"
  ]
}

const keyboardDisplay = {
  "{enter}": "Enter",
  "{bksp}": "⌫",
}


let buffer = ""

var buttonTheme = [
  {
    class: "correct",
    buttons: " ",
  },
  {
    class: "present",
    buttons: " ",
  },
  {
    class: "not-found",
    buttons: " ",
  },
]

const Gamekeyboard = ({ inWordList, makeGuess, renderCurrent }) => {

  function keyPressed(key) {
    if (key === "{bksp}") {
      buffer = buffer.slice(0, -1)
      renderCurrent(buffer)
    } else if (key === "{enter}") {
      if (!inWordList(buffer)) {
        alert("'" + buffer + "' not in word list")
        return
      }
      makeGuess(buffer)
      buffer = ""
    } else if (buffer.length < 5) {
      buffer += key
      renderCurrent(buffer)
    }
    console.info("keypress", key, buffer)
  }

  return (
    <>
      <Keyboard layout={keyboardLayout} display={keyboardDisplay} onKeyPress={keyPressed} buttonTheme={buttonTheme}>
        {JSON.stringify(buttonTheme)}
      </Keyboard>
    </>
  )
}
export default Gamekeyboard