import './App.css';
import Nav from './components/Nav'
import Gamekeyboard from './components/Keyboard'
import Gameboard from './components/Gameboard'
import { RandomWord, InWordList } from './words'
import { useState } from 'react';

function App() {
  const [targetWord, setTargetWord] = useState(RandomWord())
  const [data, setData] = useState(["", "", "", "", "", ""])
  const [currentRow, nextRow] = useState(0)


  function makeGuess(s) {
    nextRow(currentRow + 1)
    console.log("making guess", s, currentRow)
    // Winner / loser alerts
    if (s === targetWord) {
      alert("'" + s + "' You win!")
      resetGame()
    } else if (currentRow >= 5) {
      alert("'" + targetWord + "' Not quite today.")
      resetGame()
    }
  }

  function resetGame() {
    setTargetWord(RandomWord())
    setData(["", "", "", "", "", ""])
    nextRow(0)
  }

  function renderCurrent(s) {
    data[currentRow] = s
    setData([...data])
  }



  return (
    <div className="App">
      <Nav />
      <div className='game'>
        <Gameboard targetWord={targetWord} data={data} currentRow={currentRow} />
        <Gamekeyboard inWordList={InWordList} renderCurrent={renderCurrent} makeGuess={makeGuess} />
      </div>
    </div>
  );
}

export default App;
