
const Gameboard = ({ targetWord, currentRow, data }) => {

  function Row({ guess, active, targetWord }) {
    console.log("creating row", guess, targetWord, active)

    let className = "row"
    if (active) {
      className += " active"
    }

    return (
      <div className={className}>
        {[0, 1, 2, 3, 4].map(
          i => <Tile guess={guess} targetWord={targetWord} ndx={i} rowActive={active} key={i} />
        )
        }
      </div>
    )
  }

  function Tile({ guess, targetWord, rowActive, ndx }) {
    let className = "tile "
    // console.log("creating Tile", guess, word, ndx)
    const char = targetWord.charAt(ndx)
    let guessChar = ""

    if (guess !== "") {
      guessChar = guess.charAt(ndx)
      if (!rowActive) {
        if (guessChar === char) {
          className += "correct"
          // TODO handle character reuse
        } else if (targetWord.includes(guessChar)) {
          className += "present"
        } else {
          className += "not-found"
        }
      }
    }

    return <div className={className}>{guessChar}</div>;
  }

  return (
    <>
      <div className="gameBoard">
        {[0, 1, 2, 3, 4, 5].map(
          i => <Row guess={data[i]} active={i === currentRow} targetWord={targetWord} key={i} />
        )}
      </div>
    </>
  )
}

export default Gameboard